import { useState, useEffect } from "react";
import axios from "axios";
import { useUseContext } from "./useUseContext";
import { useNavigate } from "react-router";
const useFetchListWords = ({ URL }) => {
  const { type, status, letter, filter } = useUseContext();
  const navigate = useNavigate();
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  // console.log(type, status, letter, filter.value, "fil");
  const [words, setWords] = useState([]); //for store
  let lowerCaseLetter = letter.toLowerCase();
  let [filterWords, setFilterWords] = useState([]); //for filter
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(URL, {
          params: {
            time_range: filter.value,
            type: type,
            status: status,
            letter: lowerCaseLetter,
          },
          withCredentials: true,
        });
        setWords(response.data.texts);
        setFilterWords(response.data.texts);
        // console.log(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      }
    };

    fetchData(); // Call the function when the component mounts
  }, [type, status, letter, filter]);
  return { words, setWords, filterWords, setFilterWords };
};

export { useFetchListWords };
