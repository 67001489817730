import axios from 'axios';
import Cookie from 'universal-cookie';

export async function getTokenOrRefresh() {
    const apiKey = process.env.REACT_APP_API_URL;
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');
    // console.log(speechToken)
    if (speechToken === undefined) {
        try {
            const res = await axios.get(`${apiKey}/get-speech-token`);
            const token = res.data.token;
            const region = res.data.region;
            cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'});
            return { authToken: token, region: region };
        } catch (err) {

            return { authToken: null, error: err.response.data };
        }
    } else {

        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}