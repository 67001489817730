import { useRef, useEffect } from "react";

const useScroll = () => {
  const divRef = useRef(null);
  useEffect(() => {
    divRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
  });
  return { divRef };
};

export { useScroll };
