import React from "react";
import ChartComponent from "./Chart/ChartComponent";
import ChartForTwoData from "./Chart/ChartForTwoData";
import ChartGraphDet from "./Chart/ChartGraphDet";
export default function Chart({
  acquiredData,
  isDataAcquired,
  sourceData,
  isSourceDataAcquired,
  isWord,
}) {
  // let articleWords = data.acquired?.word?.article || 0;
  // let videoWords = data.acquired?.word?.video || 0;
  // let manualWords = data.acquired?.word?.manual || 0;
  // total words acquired calculation
  // let totalWords =
  //   (articleWords.E || 0) +
  //   (articleWords.U || 0) +
  //   (videoWords.E || 0) +
  //   (videoWords.U || 0) +
  //   (manualWords.E || 0) +
  //   (manualWords.U || 0);
  // let basicWords = Math.round(
  //   (((articleWords.B || 0) + (videoWords.B || 0) + (manualWords.B || 0)) /
  //     totalWords) *
  //     100
  // );
  // let EssentialWords = Math.round(
  //   (((articleWords.E || 0) + (videoWords.E || 0) + (manualWords.E || 0)) /
  //     totalWords) *
  //     100
  // );
  // let UncategorizedWords = Math.round(
  //   (((articleWords.U || 0) + (videoWords.U || 0) + (manualWords.U || 0)) /
  //     totalWords) *
  //     100
  // );
  //for acquired chart
  // const acquiredData = [
  //   // { value: basicWords || 0 },
  //   { value: EssentialWords || 0 },
  //   { value: UncategorizedWords || 0 },
  // ];
  // const acquiredData = [{ value: 0 }, { value: 0 }, { value: 0 }];
  // const isDataAcquired = acquiredData.reduce((acc, cur) => acc + cur.value, 0);

  // // total words by source calculation
  // let totalWordFromArticles = (articleWords.E || 0) + (articleWords.U || 0);
  // let totalWordFromVideos = (videoWords.E || 0) + (videoWords.U || 0);
  // let totalWordFromManual = (manualWords.E || 0) + (manualWords.U || 0);
  // let wordsFromArticles = Math.round(
  //   (totalWordFromArticles / totalWords) * 100
  // );

  // let wordsFromVideos = Math.round((totalWordFromVideos / totalWords) * 100);

  // let wordsFromManual = Math.round((totalWordFromManual / totalWords) * 100);
  // //for source chart
  // const sourceData = [
  //   { value: wordsFromVideos || 0 },
  //   { value: wordsFromArticles || 0 },
  //   { value: wordsFromManual || 0 },
  // ];
  // // const sourceData = [{ value: 0 }, { value: 0 }];
  // const isSourceDataAcquired = sourceData.reduce(
  //   (acc, cur) => acc + cur.value,
  //   0
  // );

  return (
    <div className="mt-[48px] flex gap-[48px]">
      <section className=" py-[24px] px-[48px] bg-white rounded-[12px] flex flex-col items-center ">
        {isDataAcquired > 0 ? (
          <>
            <section className="relative">
              <ChartForTwoData data={acquiredData} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-[18px]">
                {isWord ? (
                  <>
                    {" "}
                    <p>Words</p>
                    <p>Acquired</p>
                  </>
                ) : (
                  <>
                    {" "}
                    <p>Phrases</p>
                    <p>Acquired</p>
                  </>
                )}
              </div>
            </section>

            <section>
              {" "}
              <div className="flex gap-[24px]">
                {/* <ChartGraphDet name={"Basic"} color={"bg-[#2E2E30]"} /> */}
                <ChartGraphDet name={"Essential"} color={"bg-[#AA7C48]"} />
                <ChartGraphDet name={"Uncategorized"} color={"bg-[#808080]"} />
              </div>
            </section>
          </>
        ) : (
          <p className="flex items-center justify-center h-[200px] w-[170px]">
            No Results Found
          </p>
        )}
      </section>

      <section className=" py-[24px] px-[48px] bg-white rounded-[12px] flex flex-col items-center">
        {isSourceDataAcquired > 0 ? (
          <>
            {" "}
            <section className="relative">
              <ChartComponent data={sourceData} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-[18px]">
                <p>By</p>
                <p>Source</p>
              </div>
            </section>
            <section>
              {" "}
              <div className="flex gap-[24px]">
                <ChartGraphDet name={"Videos"} color={"bg-[#2E2E30]"} />
                <ChartGraphDet
                  name={"Online Articles"}
                  color={"bg-[#AA7C48]"}
                />
                <ChartGraphDet name={"Manual"} color={"bg-[#808080]"} />
              </div>
            </section>
          </>
        ) : (
          <p className="flex items-center justify-center h-[200px] w-[170px]">
            No Results Found
          </p>
        )}
      </section>
    </div>
  );
}
