import React, { useState, useRef } from "react";
import SearchIcon from "../../../Assets/Svgs/searchIcon.svg";
import { useUseContext } from "../../../Hooks/useUseContext";
import axios from "axios";
import "../Dashboard.css";

const alphabetArray = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J",
  "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T",
  "U", "V", "W", "X", "Y", "Z",
];

const SearchWords = ({
  data,
  setFilterWords,
  setPage,
  lastIndex,
  setPageIndex,
}) => {
  let newWords = data;
  const { setLetter, alpInd, setAlpInd, type, status, letter, filter } = useUseContext();
  const debounceTimeoutRef = useRef(null); // Reference to store debounce timeout

  function handleLetter(alp, i) {
    setLetter(alp);
    setAlpInd(i);
    setPageIndex([1, 2, lastIndex]);
    setPage(1);
  }

  // Debounced change handler
  function handleChange(e) {
    const inputValue = e.target.value;

    // Clear previous timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new timeout
    debounceTimeoutRef.current = setTimeout(() => {
      if (inputValue.trim() === "") {
        setFilterWords([...data]);
      } else {
        const fetchData = async () => {
          const apiKey = process.env.REACT_APP_API_URL;
        
          try {
            const response = await axios.get(`${apiKey}/filter_texts_by_letter`, {
              params: {
                time_range: filter.value,
                type: type,
                status: status,
                letter: inputValue,
              },
              withCredentials: true,
            });
            
            setFilterWords(response.data.texts);
          } catch (error) {
            console.log('error', error);
            if (error.response && error.response.status === 401) {
            
            }
          }
        };

        fetchData();
      }
    }, 500); // Adjust the debounce delay as needed (300ms is common)
  }

  let alphabet = alphabetArray.map((alp, ind) => {
    return (
      <div
        key={ind}
        className={`${
          alpInd === ind && "button"
        } text-[18px] rounded-[50%] px-2 py-[1px]  bg-[#FFF5EB] cursor-pointer`}
        onClick={() => handleLetter(alp, ind)}>
        {alp}
      </div>
    );
  });

  return (
    <div>
      <div className="border px-[16px] py-[8px] rounded-[6px] flex items-center gap-[5px]">
        <img src={SearchIcon} alt="icon" />
        <input
          onChange={(e) => handleChange(e)}
          type="text"
          className="w-full bg-transparent border-none outline-none focus:ring-0 placeholder-[#969697] placeholder-text-[18px]"
          placeholder="Search..."
        />
      </div>
      <div className="flex gap-[10px] flex-wrap mt-[24px]">{alphabet}</div>
    </div>
  );
};

export default SearchWords;
