import React, { useState, useEffect } from "react";
// import "./Video.css";
function extractVideoID(url) {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return null;
  }
}
function createThumbnailUrl(videoId) {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
}

const getVideoTitle = async (videoId) => {
  try {
    const response = await fetch(
      `https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`
    );
    const data = await response.json();
    return data.title;
  } catch (error) {
    console.error("Error fetching video title:", error);
    return null;
  }
};

const Video = ({ videoLink }) => {
  const videoId = extractVideoID(videoLink);
  const thumbnailUrl = videoId ? createThumbnailUrl(videoId) : "";
  const [title, setTitle] = useState(null);

  useEffect(() => {
    const fetchVideoTitle = async () => {
      if (videoId) {
        const videoTitle = await getVideoTitle(videoId);
        setTitle(videoTitle);
      }
    };
    fetchVideoTitle();
  }, [videoId]);

  return (
    <div className="video-main rounded-xl bg-white flex flex-col items-start p-4 h-auto w-[25%]">
      <div>
        {thumbnailUrl ? (
          <img
            src={thumbnailUrl}
            alt="Video Thumbnail"
            className="border-2 rounded-lg w-full h-full"
          />
        ) : (
          <p>Invalid YouTube link</p>
        )}
      </div>
      <div className="video-text text-sm font-normal leading-6 flex justify-between w-full">
        <div>{title && <div> </div> && <div>{title}</div>}</div>
        <div>
          <a href={videoLink} target="_blank" rel="noopener noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
            >
              <path
                d="M11.3606 22.6162L12.7748 24.0304L21.2601 15.5451L22.6743 16.9593L24.0885 15.5451L22.6743 14.1309L24.0885 12.7167L22.6743 11.3025L21.2601 12.7167L19.8459 11.3025L18.4316 12.7167L19.8459 14.1309L11.3606 22.6162ZM15.6032 12.7167L17.0174 11.3025L18.4316 12.7167L17.0174 14.1309L15.6032 12.7167ZM15.6032 12.7167L14.189 14.1309L12.7748 12.7167L14.189 11.3025L15.6032 12.7167ZM22.6743 19.7877L24.0885 18.3735L22.6743 16.9593L21.2601 18.3735L22.6743 19.7877ZM22.6743 19.7877L21.2601 21.202L22.6743 22.6162L24.0885 21.202L22.6743 19.7877Z"
                fill="black"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Video;
