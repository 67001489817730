import React from "react";
import { ProgressCircle } from "./WordChart/ProgressArc";

const WordPowerChart = ({ name, vocabularyStrength }) => {
  return (
    <div className="px-[48px] py-[25px] bg-white rounded-[12px] flex flex-col justify-center items-center">
      <p className="pb-[48px] text-[18px] font-semibold ">{name}</p>
      <ProgressCircle vocabularyStrength={vocabularyStrength} />
    </div>
  );
};

export default WordPowerChart;
