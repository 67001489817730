import React, { useState } from "react";
import Profile from "./Profile.jsx";
import Pricings from "./Pricings";

const ProfilePlans = ({ premium, setRerender }) => {
  const [toggle, setToggle] = useState(false);
  // console.log(premium, "status");
  return (
    <div className="mt-[100px] 5xl:mx-56 4xl:mx-20 3xl:mx-8 ss:mx-0 grid grid-cols-8  border rounded-[12px] bg-white">
      <section className="5xl:p-[48px] 4xl:p-[48px] 3xl:p-[48px] ss:p-[28px] col-span-2 text-[18px]">
        <div
          onClick={() => setToggle(false)}
          className={`${
            !toggle ? "border-[#2E2E30]" : ""
          } border-s-[4px] 5xl:px-[24px] 4xl:px-[24px] 3xl:px-[24px] ss:px-[24px] py-[12px]  hover:border-[#2E2E30] cursor-pointer`}
        >
          Update Plan
        </div>
        <div
          onClick={() => setToggle(true)}
          className={`${
            toggle ? "border-[#2E2E30]" : ""
          } border-s-[4px] px-[24px] py-[12px] hover:border-[#2E2E30] cursor-pointer mt-[12px]`}
        >
          Edit Profile
        </div>
      </section>
      <section className="p-[48px] border-s col-span-6 ">
        {toggle && (
          <div>
            <h3 className="text-[18px] font-bold mb-[24px]">Edit Profile</h3>
            <Profile />
          </div>
        )}
        {!toggle && (
          <section>
            <div className="mb-[24px] flex justify-between items-center">
              <h3 className="text-[18px] font-bold ">Your Current Plan</h3>
              <div className="  text-[12px] flex  items-center">
                <div
                  className={`${
                    premium.status === "Inactive"
                      ? "bg-[#009883] border-transparent text-white"
                      : ""
                  } py-[12px] px-[24px]  rounded-s-[12px]
                 border `}
                >
                  Free
                </div>
                <div
                  className={`${
                    premium.status === "Active"
                      ? "bg-[#009883] border-transparent text-white"
                      : ""
                  } text-black rounded-e-[12px]  py-[12px] px-[12px] border-y border-e`}
                >
                  Premium
                </div>
              </div>
            </div>
            <Pricings premium={premium} setRerender={setRerender} />
          </section>
        )}
      </section>
    </div>
  );
};

export default ProfilePlans;
