import React from "react";
import "../../Dashboard/Dashboard.css";
import PracticeButton from "../../Buttons/PracticeButton";
const Sources = ({
  img,
  i,
  active,
  setActive,
  bySource,
  setSourceValue,
  source,
  value,
  setDay,
  words = 0,
}) => {
  // const isActive = id === active;
  function handleToggle() {
    setActive((prev) => ({ ...prev, UI: source }));
    setSourceValue(bySource);
    setDay(null);
  }
  return (
    <div
      key={i}
      onClick={handleToggle}
      className={`${
        source === active.UI ? `bg-[#FFF5EB]` : "bg-white"
      } 5xl:px-[48px] 4xl:px-[38px] 3xl:px-[38px] ss:px-[28px] py-[24px] border-[2px] rounded-[12px] text-center leading-none group hover:bg-[#FFF5EB] shadow-lg cursor-pointer`}
    >
      <div className="flex justify-center pb-[24px]">
        <img src={img} alt="img" />
      </div>
      <div className="text-[22px] font-medium">{source}</div>
      <div className="text-[#AA7C48] text-[64px] pt-[24px] pb-[48px] leading-none font-[Cardo] font-bold">
        {words}
      </div>
      <PracticeButton />
    </div>
  );
};

export default Sources;
