import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Sidebar from "../Components/Sidebar/Sidebar";
import ProfilePlans from "../Components/Settings/Profile_Plans";
import axios from "axios";
export default function Settings() {
  const [premium, setPremium] = useState({});
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_API_URL;
  const [render, setRerender] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiKey}/check_plan`, {
          withCredentials: true,
        });
        // console.log(response.data, "res");
        // console.log(response.data, "res")
        setPremium(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchData();
  }, [render]);
  return (
    <div className="flex bg-[#FBF9F8] overflow-y-hidden ">
      <div className="sticky top-0 bg-white">
        <Sidebar />
      </div>

      <div className="grow  h-screen">
        <ProfilePlans premium={premium} setRerender={setRerender} />
      </div>
    </div>
  );
}
