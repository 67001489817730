import React, { useState, useEffect } from "react";
import { getTokenOrRefresh } from "../../util/token_utils";
import audioOff from "./audioOff.svg";
import audioOn from "./audioOn.svg";
const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

export default function Speech({ SpeechText }) {
  const [player, updatePlayer] = useState({ p: undefined, muted: false });
  const [audioClose, setAudioClose] = useState(true);
  const [resumeAudio, setResumeAudio] = useState(false);
  const [currentSpeechText, setCurrentSpeechText] = useState("");

  useEffect(() => {
    // Pause audio when speech text changes
    if (player.p) {
      player.p.pause();
      setResumeAudio(true);
    }
    // Store the new speech text and reset audio state
    setCurrentSpeechText(SpeechText);
    setAudioClose(true);
    setResumeAudio(false);
  }, [SpeechText]);

  async function textToSpeech(text) {
    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    const myPlayer = new speechsdk.SpeakerAudioDestination();
    updatePlayer({ p: myPlayer, muted: false });
    const audioConfig = speechsdk.AudioConfig.fromSpeakerOutput(myPlayer);
    let synthesizer = new speechsdk.SpeechSynthesizer(
      speechConfig,
      audioConfig
    );

    setAudioClose(false);
    synthesizer.speakTextAsync(
      text,
      (result) => {
        let text;
        if (
          result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted
        ) {
          text = `synthesis finished for "${text}".\n`;
        } else if (result.reason === speechsdk.ResultReason.Canceled) {
          text = `synthesis failed. Error detail: ${result.errorDetails}.\n`;
        }
        synthesizer.close();
        synthesizer = undefined;
      },
      function (err) {
        synthesizer.close();
        synthesizer = undefined;
      }
    );
  }

  const handleMute = async () => {
    updatePlayer((p) => {
      if (!p.muted) {
        p.p.pause();
        setResumeAudio(true);
        return { ...p, muted: true };
      } else {
        p.p.resume();
        setResumeAudio(false);
        return { ...p, muted: false };
      }
    });
  };

  return (
    <div>
      <div>
        <div>
          {!resumeAudio && audioClose ? (
            <div>
              <i>
                <img
                  onClick={() => textToSpeech(currentSpeechText)}
                  className="w-11"
                  src={audioOff}
                  alt="icon"
                />
              </i>
            </div>
          ) : resumeAudio ? (
            <div>
              <i>
                <img
                  onClick={() => handleMute()}
                  className="w-11"
                  src={audioOff}
                  alt="icon"
                />
              </i>
            </div>
          ) : (
            <div>
              <i>
                <img
                  onClick={() => handleMute()}
                  className="w-11"
                  src={audioOn}
                  alt="icon"
                />
              </i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
