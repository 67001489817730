import React from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import WordPowerUser from "../Components/WordPower/UserWordPowerUser";
// import { useFetch } from "../Hooks/useFetch";
import { useUseContext } from "../Hooks/useUseContext";
const WordPower = () => {
  const { data } = useUseContext();

  // const apiKey = process.env.REACT_APP_API_URL;
  // const { data } = useFetch({
  //   URL: `${apiKey}/metrics`,
  //   fetchId: "data",
  // });
  // console.log(data, "word power");
  //reusable variables
  let articleWords = data.acquired?.word?.article || 0;
  let videoWords = data.acquired?.word?.video || 0;
  let manualWords = data.acquired?.word?.manual || 0;
  // let articlePhrases = data.acquired?.phrase?.article || 0;
  // let videoPhrases = data.acquired?.phrase?.video || 0;
  // let manualPhrases = data.acquired?.phrase?.manual || 0;
  //for practicedwords and phrases
  let practicedWords = data?.practised?.word?.practised || 0;
  let practicedPhrases = data?.practised?.phrase?.practised || 0;
  //for words and phrase i know
  let skippedWords = data?.practised?.word?.skipped || 0;
  let skippedPhrases = data?.practised?.phrase?.skipped || 0;
  // let basicWords =
  //   (articleWords.B || 0) + (videoWords.B || 0) + (manualWords.B || 0);
  let unCategorizedWords =
    (articleWords.U || 0) + (videoWords.U || 0) + (manualWords.U || 0);
  //for totalwords and phrase acquired
  // let totalWordsAcquired =
  //   (articleWords.B || 0) +
  //   (articleWords.E || 0) +
  //   (articleWords.U || 0) +
  //   (videoWords.B || 0) +
  //   (videoWords.E || 0) +
  //   (videoWords.U || 0) +
  //   (manualWords.B || 0) +
  //   (manualWords.E || 0) +
  //   (manualWords.U || 0);
  // let totalPhrasesAcquired =
  //   (articlePhrases.B || 0) +
  //   (articlePhrases.E || 0) +
  //   (articlePhrases.U || 0) +
  //   (videoPhrases.B || 0) +
  //   (videoPhrases.E || 0) +
  //   (videoPhrases.U || 0) +
  //   (manualPhrases.B || 0) +
  //   (manualPhrases.E || 0) +
  //   (manualPhrases.U || 0);
  let vocabularyStrength =
    ((practicedWords + skippedWords + 17652 - unCategorizedWords) / 84945) *
      100 || 0;

  let phrasalStrength =
    ((practicedPhrases + skippedPhrases) / 84692) * 100 || 0;

  return (
    <div className="flex overflow-y-hidden">
      <div className="sticky top-0 ">
        <Sidebar />
      </div>

      <div className="w-full p-[48px] bg-[#FBF9F8]">
        <main className="mt-[100px]">
          <WordPowerUser
            vocabularyStrength={
              isFinite(vocabularyStrength) ? vocabularyStrength : 0
            }
            phrasalStrength={isFinite(phrasalStrength) ? phrasalStrength : 0}
          />
        </main>
      </div>
    </div>
  );
};

export default WordPower;
