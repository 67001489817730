import React from "react";
import moment from "moment";
import Celebration from "../../Assets/Svgs/celebration-spark.svg";
const CurrentPlan = ({ premium }) => {
  const originalMoment = moment(premium.end_time);
  let plan;
  if (premium.plan === "week") plan = "weekly";
  if (premium.plan === "month") plan = "monthly";
  if (premium.plan === "annual") plan = "annual";
  // Format the date in "DD/MM/YYYY" format
  const formattedDate = originalMoment.format("DD/MM/YYYY");

  return (
    <div className="flex mb-[24px] border-[1px] rounded-[12px]">
      <section className=" bg-[#FCF7F1] py-[24px] px-[24px] rounded-s-[12px]">
        <img className="m-auto" src={Celebration} alt="spark" />
        <div className="font-[Cardo] text-[18px] mt-[8px]">
          Happy Glibztering!
        </div>
      </section>
      <section className="bg-white flex flex-col justify-between py-[24px] px-[24px] text-[14px]">
        <div>
          Thank you for availing the {plan} subscription of
          <span className="text-[#AA7C48]"> Glibzter</span>{" "}
          <span className="text-[#2E2E3080]"> Immersive</span>.
        </div>
        <div>
          Your subscription is valid until{" "}
          <span className="font-bold ">{formattedDate}</span>
        </div>
      </section>
    </div>
  );
};

export default CurrentPlan;
