import React from "react";
import YoutubeIcon from "../../Assets/Svgs/youtubeIcon.svg";
import netflixIcon from "../../Assets/Svgs/netflixIcon.svg";
import TOIIcon from "../../Assets/Svgs/TOIIcon.svg";
import msnIcon from "../../Assets/Svgs/msnIcon.svg";
import scrollIcon from "../../Assets/Svgs/scrollIcon.svg";
import bbcIcon from "../../Assets/Svgs/bbcIcon.svg";
import cnnIcon from "../../Assets/Svgs/cnnIcon.svg";
const sources = [
  {
    source: "Netflix",
    img: netflixIcon,
    width: "28px",
    Url: "https://www.netflix.com/in/",
  },
  {
    source: "YouTube",
    img: YoutubeIcon,
    width: "30px",
    Url: "https://www.youtube.com/",
  },

  {
    source: "Scroll.in",
    img: scrollIcon,
    width: "28px",
    Url: "https://scroll.in/",
  },
  {
    source: "BBC",
    img: bbcIcon,
    width: "30px",
    Url: "https://www.bbc.com/",
  },

  {
    source: "CNN",
    img: cnnIcon,
    width: "30px",
    Url: "https://edition.cnn.com/",
  },

  {
    source: "MSN",
    img: msnIcon,
    width: "20px",
    Url: "https://www.msn.com/",
  },

  {
    source: "The Times Of India",
    img: TOIIcon,
    width: "35px",
    Url: "https://timesofindia.indiatimes.com/",
  },
];
const Source = () => {
  const mapSource = sources.map((article, ind) => {
    const IconWidth = { width: article.width };
    return (
      <a
        href={article.Url}
        target="_blank"
        rel="noreferrer"
        key={ind}
        className="leading-none flex gap-[2px] items-center 5xl:px-[16px] 4xl:px-[14px] 3xl:px-[12px] ss:px-[6px] py-[0px] border rounded-[8px] bg-[#FCF7F1]"
      >
        <div>
          <img style={IconWidth} src={article.img} alt="img" />
        </div>
        <div className="5xl:text-[18px] 4xl:text-base 3xl:text-sm ss:text-xs ml-1">
          {article.source}
        </div>
      </a>
    );
  });
  return <div className="flex gap-[16px]">{mapSource}</div>;
};

export default Source;
