import { numberConversion } from "../../../Helpers/numberConversion";
const BarAxis = ({ data }) => {
  let graphData = data;
  if (!isFinite(data)) graphData = 0;
  let data_100 = numberConversion(Math.round(graphData));
  let data_75 = numberConversion(Math.round(graphData * 0.75));
  let data_50 = numberConversion(Math.round(graphData * 0.5));
  let data_25 = numberConversion(Math.round(graphData * 0.25));

  return (
    <div className="flex flex-col gap-[48px]">
      <div>{data_100}</div>
      <div className="invisible">{data_75}</div>
      <div className={`${data_100 > 1 ? "visible" : "invisible"}`}>
        {data_50}
      </div>
      <div className="invisible">{data_25}</div>
      <div>0</div>
    </div>
  );
};

export default BarAxis;
