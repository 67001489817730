import React, { useState, useEffect, useRef } from "react";
import DropdownArrow from "../../Assets/Svgs/DropdownArrow.svg";
import { useUseContext } from "../../Hooks/useUseContext";
const FilterBy = [
  { id: 0, UI: "Today", value: "today" },
  { id: 1, UI: "Yesterday", value: "yesterday" },
  { id: 2, UI: "Last Week", value: "last_week" },
  { id: 3, UI: "Last Month", value: "last_month" },
  { id: 4, UI: "Last Year", value: "last_year" },
  { id: 5, UI: "Till Date", value: "all_time" },
];

const Filter = () => {
  const { filter, setFilter } = useUseContext();
  const [isOpen, setIsOpen] = useState(false); //for dropdown modal
  const dropdownRef = useRef(null);
  //function for setting filter value and change UI
  function changeFilter(value, UI) {
    setFilter((prev) => ({ ...prev, value: value, UI: UI }));
  }
  //maping options
  const Options = FilterBy.map((el, i) => {
    return (
      <div
        key={i}
        className="hover:bg-[#ACD4F7] py-[6px] px-[24px] cursor-pointer transition-all"
        onClick={() => changeFilter(el.value, el.UI)}>
        {el.UI}
      </div>
    );
  });
  //to close fiter modal
  function handleEscapeKey(event) {
    if (event.key === "Escape") {
      // Your code to handle the escape key press goes here
      setIsOpen(false);
    }
  }
  document.addEventListener("keydown", handleEscapeKey);
  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <main
      className="relative cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
      ref={dropdownRef}>
      <section className="border rounded-[12px] py-[12px] px-[24px] flex items-center">
        <div className="w-[100px] leading-none">{filter.UI}</div>
        <div>
          <img src={DropdownArrow} alt="arrow" />
        </div>
      </section>
      {isOpen && (
        <section className="absolute border rounded-[12px] shadow-lg   py-[12px] mt-2 w-full bg-white ">
          {Options}
        </section>
      )}
    </main>
  );
};

export default Filter;
