import { createContext, useState, useEffect } from "react";

export let userContext = createContext();
const InitaialData = {
  acquired: {
    word: {
      video: {
        E: 0,
        B: 0,
        U: 0,
      },
      article: {
        E: 0,
        U: 0,
        B: 0,
      },
      manual: {
        E: 0,
        U: 0,
        B: 0,
      },
    },
  },
  practised: {
    word: {},
  },
};
let InitialState = {
  contact_number: "",
  location: "",
  Institute: "",
  Institute_name: "",
};

const Context = ({ children }) => {
  const [auth, setAuth] = useState(null); //for auth verification
  const [filter, setFilter] = useState({ UI: "Today", value: "today" }); //used to filter based on time basis
  const [data, setData] = useState(InitaialData); //scraped data's of the user
  const [dataByTime, setDataByTime] = useState({}); //date's of the data
  const [active, setActive] = useState({ UI: "All" }); //filter based on source
  const [difficulty, setDifficulty] = useState("all"); //filter via chart
  const [sourceValue, setSourceValue] = useState("all");
  const [practiceWords, setPracticeWords] = useState([]);
  const [trackWordsStatus, setTrackWordsStatus] = useState(false); // to skip words total (for trigger useEffect)
  //filter for known and skipped list
  const [type, setType] = useState("word");
  const [status, setStatus] = useState(null);
  const [letter, setLetter] = useState("A");
  const [alpInd, setAlpInd] = useState(0); //to store alphabet index
  const [userInfo, setUserInfo] = useState(InitialState);
  // const [rerender, setRerender] = useState(true); //to trigger rerender
  //admin status
  const [day, setDay] = useState(null);
  //effects
  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("filter"));
    // console.log(storedData);
    if (storedData) setFilter(storedData);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      sessionStorage.setItem("filter", JSON.stringify(filter));
    }, 2000);
  }, [filter]);

  return (
    <userContext.Provider
      value={{
        filter,
        setFilter,
        data,
        setData,
        dataByTime,
        setDataByTime,
        auth,
        setAuth,
        active,
        setActive,
        difficulty,
        setDifficulty,
        sourceValue,
        setSourceValue,
        practiceWords,
        setPracticeWords,
        trackWordsStatus,
        setTrackWordsStatus,
        type,
        setType,
        status,
        setStatus,
        letter,
        setLetter,
        alpInd,
        setAlpInd,
        userInfo,
        setUserInfo,
        day,
        setDay
      }}
    >
      {children}
    </userContext.Provider>
  );
};
export default Context;
