import React from "react";
import Line from "../../Assets/Svgs/Line.svg";
import add from "../../Assets/Svgs/add.svg";
// import "./Ongoing.css";
import { Link } from "react-router-dom";
import { useUseContext } from "../../Hooks/useUseContext";
import brown from "../../Assets/Svgs/brown.svg";
const Ongoing = ({ day, linkTo, theme }) => {
  const { setDay } = useUseContext();

  return (
    <div className="">
      <div className="bg-[#AA7C48] p-[10px] rounded-t-[18px] text-center text-white 5xl:text-sm 4xl:text-sm 3xl:text-sm ss:text-xs">
        {theme}
      </div>
      <div className="main-1 flex flex-col p-4 gap-4 5xl:w-72 4xl:w-60 3xl:w-60 ss:w-42 items-center rounded-b-xl bg-white border border-[#E7E7E7] shadow-combined ">
        <div className="first-1 text-[#2E2E30] 5xl:text-sm 4xl:text-sm 3xl:text-sm ss:text-sm font-normal leading-4">
          Add to practice
        </div>
        <div className="second-1">
          <img src={Line} alt="Line" />
        </div>
        <div
          className="third-1 w-40 h-28 flex justify-center items-center bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${brown})`, backgroundSize: "85%" }}
        >
          <div className="third-text-1 flex flex-col bg-white pt-0 px-2 rounded-md">
            <div
              className="text-first-1 text-[#2e2e30] text-base leading-5 pt-1 font-bold"
              style={{ fontFamily: "cardo" }}
            >
              Day
            </div>
            <div
              className="text-second-1 flex justify-center items-center text-[#2e2e30] text-3xl font-bold leading-9"
              style={{ fontFamily: "cardo" }}
            >
              <div>{day}</div>
            </div>
          </div>
        </div>
        <div className="fourth-1">
          <img src={Line} alt="Line" />
        </div>

        <div className="fifth-1 cursor-pointer flex flex-col items-center gap-4 bg-[#fff5eb] p-2 rounded-md w-full">
          <Link
            className="w-full"
            to={
              linkTo
                ? `/startLearning?day=${day}`
                : `/startLearnPhrases?day=${day}`
            }
            onClick={() => {
              setDay(day);
            }}
          >
            <div className="flex flex-col justify-center items-center gap-[1rem]">
              <div>
                <img src={add} alt="Add" />
              </div>
              <div className="5xl:text-lg 4xl:text-lg 3xl:text-base ss:text-base text-[#AA7C48] font-semibold">
                Add to Practice
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Ongoing;
