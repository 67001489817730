import { React } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./Auth/SignIn";
import Dashboard from "./Routes/Dashboard";
import WordPower from "./Routes/WordPower";
import Settings from "./Routes/Settings";
import StartLearning from "./Routes/StartLearning";

import GlibzterAi from "./Routes/GlibzterAi";
import Context from "./ContextApi/Context";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import PrivateRoutes from "./util/PrivateRoutes";
import StartLearningPhrases from "./Routes/StartLearningPhrases";
import Home from "./Routes/Home";
// import Test from "./Routes/Test";

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // console.log(isLoggedIn);

  return (
    <div className=" items-center h-full">
      <Router>
        <Context>
          <GoogleOAuthProvider clientId={`${clientId}`}>
            <Routes>
              <Route path="/" element={<SignIn />}></Route>
              <Route path="/home" element={<Home />}></Route>
              {/* <Route
                element={
                  <PrivateRoutes
                    isLoggedIn={isLoggedIn}
                    setIsLoggedIn={setIsLoggedIn}
                  />
                }> */}
              {/* <Route path='/test' element={<Test />}></Route> */}
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/startLearning" element={<StartLearning />}></Route>
              <Route
                path="/startLearnPhrases"
                element={<StartLearningPhrases />}
              ></Route>
              <Route path="/glibzterAI" element={<GlibzterAi />}></Route>
              <Route path="/WordPower" element={<WordPower />}></Route>
              <Route path="/settings" element={<Settings />}></Route>
              {/* </Route> */}
            </Routes>
          </GoogleOAuthProvider>
        </Context>
      </Router>
    </div>
  );
}

export default App;
