import { useEffect } from "react";
import axios from "axios";
import { useUseContext } from "./useUseContext";
import { useNavigate } from "react-router";
//fetchId to fetch which data to fetch and set based on State
const useFetch = ({ URL, fetchId }) => {
  const { data, dataByTime, setData, setDataByTime, filter } = useUseContext();
  const navigate = useNavigate();
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(URL, {
          params: {
            time_range: filter.value,
          },
          withCredentials: true,
        });

        fetchId === "data" && setData(response.data);

        fetchId === "dataByTime" && setDataByTime(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      }
    };

    fetchData(); // Call the function when the component mounts
  }, [filter]); // Empty dependency array ensures it runs only once
  return { data, dataByTime };
};
export { useFetch };
