const Validation = (userInfo) => {
  // console.log(userInfo);
  const newErrors = {};
  // console.log(newErrors);

  if (!userInfo.location) {
    newErrors.location = "*Location is required.";
  }
  // if (userInfo.location && userInfo.location.trim()) {
  //   newErrors.location = "*Location is required.";
  // }
  if(!userInfo.Institute) {
    newErrors.Institute = "*Background is required"
  }
  if(!userInfo.Institute_name) {
    newErrors.Institute_name = "*Institute/Organisation name is required"
  }
  if (!userInfo.contact_number) {
    newErrors.contact_number = "*Contact number is required.";
  } else if (!/^[0-9]+$/.test(userInfo.contact_number)) {
    newErrors.contact_number = "*Enter a valid number";
  }

  return newErrors;
};

export default Validation;

//  else if (!/^[+0-9\-() ]*$/.test(userInfo.contact_number)) {
//     newErrors.BlockNumber = "*Enter a valid number";
//   }
