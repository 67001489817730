import React from "react";
import StartPracticePhrases from "../Components/PracticeWords/StartPracticePhrases";
import { useFetch } from "../Hooks/useFetch";
const StartLearningPhrases = () => {
  //for scraped data
  const apiKey = process.env.REACT_APP_API_URL;
  const { data } = useFetch({
    URL: `${apiKey}/metrics`,
    fetchId: "data",
  });
  // console.log(data);
  return (
    <div className="bg-[#FBF9F8] lg:ps-[200px] 2xl:ps-[312px] min-h-screen h-full">
      <StartPracticePhrases data={data} />
    </div>
  );
};

export default StartLearningPhrases;
