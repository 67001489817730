function numberConversion(totalWordsAcquired) {
  let displayResult;

  if (totalWordsAcquired >= 1000000) {
    displayResult = (totalWordsAcquired / 1000000).toFixed(1) + "m";
  } else if (totalWordsAcquired >= 100000) {
    displayResult = (totalWordsAcquired / 1000).toFixed(1) + "l";
  } else if (totalWordsAcquired >= 1000) {
    displayResult = (totalWordsAcquired / 1000).toFixed(1) + "k";
  } else {
    displayResult = totalWordsAcquired.toString();
  }
  return displayResult;
}
export { numberConversion };
