import React, { useEffect, useState } from "react";
import ChatHistory from "./ChatHistory";
import ChatBot from "./ChatBot";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";

import "./chat.css";
import { Context } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.speech/SpeechServiceConfig";
// import { useUseContext } from "../../Hooks/useUseContext";
const Chat = () => {
  const apiKey = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const queryParams = new URLSearchParams(location.search);
  const wordFromParam = queryParams.get("word");
  const redirect = queryParams.get("to"); //for correct redirection
  const [wordTheme, setWordTheme] = useState(wordFromParam); //to display in input
  const [word, setWord] = useState(wordFromParam);
  const [context, setContext] = useState("");
  const [gptRes, setGptRes] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  // const { postData } = useUseContext;
  // console.log(postData);
  // console.log(word);

  // function handleSubmit() {
  //     fetchData();
  // }
  // const handleThemeSelected = () => {
  //     handleSubmit();
  // };
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      try {
        const response = await axios.get(`${apiKey}/chatgpt`, {
          params: {
            user_input: word,
            context: context,
          },
          withCredentials: true,
        });

        setGptRes((pre) => [
          ...pre,
          {
            input: word + `-` + context,
            output: response.data.message,
          },
        ]);
        setIsloading(false);
        setContext("");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      } finally {
        setIsloading(false);
      }
    };
    if (context !== "") {
      fetchData();
    }
  }, [context]);

  return (
    // <div className="flex h-screen max-w-[90vw] ml-16">
    //   <section className="min-w-[450px] overflow-y-scroll chat">
    //     <ChatHistory
    //       redirect={redirect}
    //       setWord={setWord}
    //       wordTheme={wordTheme}
    //       setWordTheme={setWordTheme}
    //       data={word}
    //       response={gptRes}
    //     />
    //   </section>
    //   <section className="w-[1470px] h-screen">
    //     <ChatBot
    //       redirect={redirect}
    //       wordTheme={wordTheme}
    //       setWordTheme={setWordTheme}
    //       data={word}
    //       response={gptRes}
    //       context={context}
    //       setContext={setContext}
    //       setWord={setWord}
    //       // fetchData={fetchData}
    //       isLoading={isLoading}
    //       setIsloading={setIsloading}
    //     />
    //   </section>
    // </div>
    <>
      <div className="min-w-full bg-[#FBF9F8] h-screen flex">
        <div className="sticky top-0 h-screen bg-white">
          <Sidebar />
        </div>
        <div className="w-[1050px] h-screen mx-auto ">
          <ChatBot
            redirect={redirect}
            wordTheme={wordTheme}
            setWordTheme={setWordTheme}
            data={word}
            response={gptRes}
            setResponse={setGptRes}
            context={context}
            setContext={setContext}
            setWord={setWord}
            // fetchData={fetchData}
            isLoading={isLoading}
            setIsloading={setIsloading}
          />
        </div>
      </div>
    </>
  );
};

export default Chat;
