import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const translateText = async (text, targetLanguage) => {
  try {
    const response = await axios.post(
      `${API_URL}/get_translated_content`,
      {
        text: text,
        target_language: targetLanguage,
      }
    );
    
    return response.data.translated_text; // Corrected access to translated text
  } catch (error) {
    console.error("Error translating text:", error);
    throw error; // Re-throw the error to be caught by the caller
  }
};

export default translateText;
