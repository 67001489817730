const moment = require("moment");

function timeConversion(time, interval) {
  const startDate = moment(time, "YYYY-MM-DD HH:mm:ss").toDate();
  // console.log(startDate);
  // const currentDate = new Date();

  // Calculate the difference in milliseconds
  // const timeDifference = currentDate - startDate;
  // Convert milliseconds to seconds
  // const secondsDifference = timeDifference / 1000;

  // Convert seconds to minutes
  // const minutesDifference = secondsDifference / 60;

  // Convert minutes to hours
  // const hoursDifference = minutesDifference / 60;
  // Convert hours to days
  // const daysDifference = hoursDifference / 24;

  // Calculate month difference

  // const startMonth = startDate.getMonth();
  // const currentMonth = currentDate.getMonth();
  // const monthDifference =
  //   currentMonth -
  //   startMonth +
  //   (currentDate.getFullYear() - startDate.getFullYear()) * 12;

  //for today ,yesterday,... filters
  if (interval === "hourly") {
    const formattedTime = moment(startDate).format("hh:mm A");
    return formattedTime;
  } else if (interval === "daily") {
    const formattedTime = moment(startDate).format("DD-MM-YYYY");

    return formattedTime;
  } else if (interval === "weekly") {
    const weekOfMonth =
      "Week " +
      (moment(startDate).isoWeek() -
        moment(startDate).startOf("month").isoWeek() +
        1);
    return weekOfMonth;
  } else if (interval === "monthly") {
    // const formattedTime = moment(startDate).format("MM");
    // console.log(startDate);
    const formattedTime = new Intl.DateTimeFormat("en", {
      month: "long",
    }).format(startDate);
    return formattedTime;
  } else if (interval === "yearly") {
    const formattedTime = moment(startDate).format("YYYY");
    return formattedTime;
  }
}
export { timeConversion };
