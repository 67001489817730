import { useEffect } from "react";
import axios from "axios";
import { useUseContext } from "./useUseContext";
import { useNavigate } from "react-router";
//fetchId to fetch which data to fetch and set based on State
const useFetchPracticeWords = ({ URL }) => {
  const {
    filter,
    trackWordsStatus,
    difficulty,
    sourceValue,
    practiceWords,
    setPracticeWords,
    // type,
  } = useUseContext();
  // console.log(type, "type");
  const navigate = useNavigate();
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(URL, {
          params: {
            time_range: filter.value,
            difficulty: difficulty,
            type: "word",
            source: sourceValue,
          },
          withCredentials: true,
        });

        setPracticeWords(response.data.texts);
        // console.log(response.data.texts);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      }
    };

    fetchData(); // Call the function when the component mounts
  }, [filter, difficulty, sourceValue, trackWordsStatus]);
  return { practiceWords };
};
export { useFetchPracticeWords };
