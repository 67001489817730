import React, { useState, useEffect } from "react";
import { useUseContext } from "../Hooks/useUseContext";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import GoogleIcon from "../Assets/Svgs/GoogleIcon.svg";
// import Logo from "../Assets/Svgs/GlibzterLogo-transparent.svg";
import Placeholder from "../Assets/Images/video-placeholder.jpg";
// import axios from "axios";

function SignIn({ setIsLoggedIn }) {
  let { setAuth } = useUseContext();
  const [loaded, setLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setLoaded(true);
  };
  const location = useLocation();

  // console.log(location);
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_API_URL;

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      // Send the authorization code to the backend server

      fetch(`${apiKey}/auth`, {
        method: "POST",

        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },

        body: "code=" + encodeURIComponent(codeResponse.code),
        credentials: "include",
      })
        .then((response) => response.json())

        .then((data) => {
          setAuth(data.message);
          if (data.message) {
            if (document.cookie) {
              const getCookieValue = (name) =>
                document.cookie
                  .match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")
                  ?.pop() || "";
              if (getCookieValue("token").length !== 0) {
                // console.log(getCookieValue("token"));
                navigate("/dashboard");
                // setIsLoggedIn(true);
              } else {
                navigate("/");
                // setIsLoggedIn(false);
              }
            }
          } //to navigate authenicated user
        })

        .catch((error) => {
          // console.error("Error:", error);
        });
    },

    onError: () => {
      // Handle login errors here
      // console.error("Google login failed");
    },

    flow: "auth-code",
  });
  useEffect(() => {
    if (document.cookie) {
      const getCookieValue = (name) =>
        document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
        "";
      if (getCookieValue("token").length !== 0 && location.pathname === "/") {
        // console.log(getCookieValue("token"));
        navigate("/home");
      } else {
        navigate("/");
      }
    }
  }, []);
  // console.log(auth);

  // //effects
  // //for getting user info
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${apiKey}/user_info`, {
  //         withCredentials: true,
  //       });
  //       console.log(response);
  //       userInfoRef.current = response.data;
  //     } catch (error) {
  //       if (error.response.status === 401) {
  //         navigate("/");
  //       }
  //     }
  //   };
  //   fetchData();
  // }, []);
  return (
    <div className="flex  justify-between h-screen">
      <section className="w-[50%] bg-[#FCF7F1] relative" >
        
        {/* <div className="ps-[48px] pt-[24px] absolute z-50" >
          <img className=" cursor-pointer " src={Logo} alt="logo" />
        </div> */}
        
        <div>
          {!loaded&&<img style={{objectFit:'initial'}} className="w-full h-full absolute z-40" src={Placeholder} alt='fallback'/>}
          <video
            onLoadedData={handleVideoLoaded} style={{objectFit:'initial'}}
            className=" cursor-pointer w-full h-full absolute min-w-full min-h-full"
            autoPlay
            loop
            muted
          >
            <source
              src="https://ewr1.vultrobjects.com/elasticdevs-videostore/VID-20240208-WA0000.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </section>
      <section className="w-[50%] flex items-center justify-center leading-none">
        <div className="shadow-md p-[48px] rounded-[12px]">
          <h1 className="font-[Cardo] font-bold text-[30px]">Create Account</h1>
          <div
            className="border rounded-xl py-[12px] px-[24px] mt-[24px] text-[14px] flex justify-between items-center gap-[12px] cursor-pointer"
            onClick={() => googleLogin()}
          >
            <section>
              <img src={GoogleIcon} alt="google" />
            </section>
            <section>
              {" "}
              <button>Sign up with Google</button>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignIn;
