import React from "react";
import Chat from "../Components/GlibzterAi/Chat";

const GlibzterAi = () => {
  return (
    <div>
      <Chat />
    </div>
  );
};

export default GlibzterAi;
