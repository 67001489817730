import * as d3 from "d3";

const drawChart = (element, data, setDifficulty, hover) => {
  const colors = ["#AA7C48", "#808080"];
  const DiffLevel = ["E", "U"];
  const boxSize = 530;

  d3.select(element).select("svg").remove(); // Remove the old svg
  // Create new svg
  const svg = d3
    .select(element)
    .append("svg")
    .attr("preserveAspectRatio", "xMidYMid meet")
    .attr("height", "225px")
    .attr("width", "225px")
    .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
    .append("g")
    .attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`);

  const arcGenerator = d3.arc().innerRadius(120).outerRadius(250);

  const pieGenerator = d3.pie().value((d) => d.value);

  const arcs = svg.selectAll().data(pieGenerator(data)).enter();

  arcs
    .append("path")
    .attr("d", arcGenerator)
    .style("fill", (d, i) => colors[i % colors.length]) // Set fill to none to make the arc transparent
    .style("stroke", "#3333331A") // Set stroke color for the arc
    .style("stroke-width", 3); // Set the width of the stroke
  // .on("mouseover", function (event, d) {
  //   // Mouseover event handler

  //   d3.select(this)
  //     .style("fill", (d, i) => colors[d.index % colors.length])
  //     .attr("cursor", "pointer");
  // });
  // .on("mouseout", function () {
  //   // Mouseout event handler
  //   d3.select(this).style("fill", "none");
  // });

  arcs
    .append("path")
    .attr("d", arcGenerator)
    .style("fill", "none") // Set fill to none to make the arc transparent
    .style("stroke", "transparent") // Set stroke color for the arc
    .style("stroke-width", 120) // Set the width of the stroke
    // .on("mouseover", function (event, d) {
    //   // Mouseover event handler
    //   hover &&
    //     d3
    //       .select(this)
    //       .style("fill", (d, i) => colors[d.index % colors.length])
    //       .attr("cursor", "pointer");
    // })
    // .on("mouseout", function () {
    //   // Mouseout event handler
    //   d3.select(this).style("fill", "none");
    // })
    .on("click", function (event, d) {
      // Click event handler
      d3.select(this).attr("cursor", "pointer");
      hover &&
        setDifficulty((stateInd, i) => DiffLevel[d.index % DiffLevel.length]);
    });

  arcs
    .append("text")
    .attr("text-anchor", "middle")
    .text((d) => `${d.data.value}%`)
    .style("fill", "#fff") // Set text color
    .style("font-size", "30px")
    .attr("transform", (d) => {
      const [x, y] = arcGenerator.centroid(d);
      return `translate(${x},${y})`;
    });
};

export default drawChart;
