import React from "react";
import Logo from "../../Assets/Svgs/GlibzterLogo.svg";
import HomeIcon from "../../Assets/Svgs/HomeIcon.svg";
import DashboardIcon from "../../Assets/Svgs/dashboardicon.svg";
import DashboardHoverIcon from "../../Assets/Svgs/dashboardwhiteicon.svg";
import HomeHoverIcon from "../../Assets/Svgs/HomeHoverIcon.svg";
import WordIcon from "../../Assets/Svgs/WordIcon.svg";
import WordHoverIcon from "../../Assets/Svgs/WordHoverIcon.svg";
import SettingsIcon from "../../Assets/Svgs/SettingsIcon.svg";
import SettingsHoverIcon from "../../Assets/Svgs/SettingsHoverIcon.svg";
import LogoutIcon from "../../Assets/Svgs/LogoutIcon.svg";
import LogoutHoverIcon from "../../Assets/Svgs/LogoutHoverIcon.svg";
import NavButtons from "../Buttons/NavButtons";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useUseContext } from "../../Hooks/useUseContext";
const Sidebar = () => {
  const location = useLocation();
  const endpoint = location.pathname.slice(1);
  const { setAuth, setDay } = useUseContext();
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  function handleLogout() {
    handleRemoveCookies();
    if (setAuth) setAuth(null);
  }
  return (
    <div className="h-screen 5xl:w-[320px] 4xl:w-[238px] 3xl:w-[238px] ss:w-[212px]">
      <div className="5xl:px-[48px] 4xl:px-[38px] 3xl:px-[18px] ss:px-[14px] py-[24px] border-b-[1px] mb-[48px] bg-white  ">
        <img className=" cursor-pointer" src={Logo} alt="logo" />
      </div>
      {/* <div className="flex flex-col gap-[400px] h-full"> */}
      <div className="flex justify-between  flex-col ">
        <section>
          {" "}
          <NavLink to="/home">
            {" "}
            <NavButtons
              img={HomeIcon}
              hoverImg={HomeHoverIcon}
              buttonName={"Home"}
              active={endpoint === "home"}
            />
          </NavLink>
          <NavLink to="/dashboard">
            {" "}
            <NavButtons
              setDay={setDay}
              img={DashboardIcon}
              hoverImg={DashboardHoverIcon}
              buttonName={"Dashboard"}
              active={endpoint === "dashboard"}
            />
          </NavLink>
          {/* <NavLink to="/wordPower">
            {" "}
            <NavButtons
              img={WordIcon}
              hoverImg={WordHoverIcon}
              buttonName={"Word Power"}
              active={endpoint === "wordPower"}
            />
          </NavLink>{" "} */}
          <NavLink to="/settings">
            {" "}
            <NavButtons
              img={SettingsIcon}
              hoverImg={SettingsHoverIcon}
              buttonName={"Settings"}
              active={endpoint === "settings"}
            />
          </NavLink>
          <NavLink to="/">
            {" "}
            <NavButtons
              setAuth={setAuth}
              img={LogoutIcon}
              hoverImg={LogoutHoverIcon}
              handleLogout={handleLogout}
              buttonName={"Logout"}
            />
          </NavLink>
        </section>
      </div>

      <div></div>
      {/* </div> */}
    </div>
  );
};

export default Sidebar;
