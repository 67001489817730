import React from "react";
import NextButtonImg from "../../Assets/Svgs/NextButton.svg";
import "./Button.css";
import { useUseContext } from "../../Hooks/useUseContext";
const BackButton = ({ handleClick }) => {
  const { trackWordsStatus, setTrackWordsStatus } = useUseContext();

  function handleChange() {
    handleClick();
    setTrackWordsStatus(!trackWordsStatus);
    // handlePostRequest();
    // setTimeout(() => setTrackWordsStatus(!trackWordsStatus), 300);
  }
  return (
    <div
      onClick={handleChange}
      className="flex items-center gap-[12px] py-[12px] px-[24px] bg-[#13CE66] rounded-[12px] inner-shadow
    cursor-pointer"
    >
      <div className="5xl:text-[26px]  text-white">Next</div>
      <div>
        <img className="w-3" src={NextButtonImg} alt="back" />
      </div>
    </div>
  );
};

export default BackButton;
