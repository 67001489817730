import React, { useEffect, useRef } from "react";
import drawChart from "./DrawChart";
const DonutChart = ({ data, setDifficulty, hover }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      drawChart(ref.current, data, setDifficulty, hover);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, data]);

  return <div className="graph" ref={ref} />;
};

export default React.memo(DonutChart);
