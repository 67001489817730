import React from "react";

const PracticeButton = () => {
  return (
    <div
      className="text-[#AA7C48] bg-[#FBF9F8] border-[1px] rounded-[12px] py-[12px] 5xl:px-[24px] 4xl:px-[22px] 3xl:px-[10px] ss:px-[10px] cursor-pointer
       group-hover:bg-[#AA7C48] group-hover:text-white group-hover:border-transparent"
    >
      Start Practice
    </div>
  );
};

export default PracticeButton;
