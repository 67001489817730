import { useState } from "react";
import SearchWords from "./SearchWords";
import WordsByOrder from "./WordsByOrder";
import { useFetchListWords } from "../../../Hooks/useFetchListWords";

const WordList = ({ gptRedirect }) => {
  const apiKey = process.env.REACT_APP_API_URL;
  //for practised list and skipped list of words
  const { words, filterWords, setFilterWords } = useFetchListWords({
    URL: `${apiKey}/filter_texts_by_letter`,
  });
  const [page, setPage] = useState(1);
  const wordsPerPage = 20;
  const startIndex = (page - 1) * wordsPerPage;
  const endIndex = page * wordsPerPage;
  const lastIndex = Math.ceil(filterWords.length / wordsPerPage);
  const [pageIndex, setPageIndex] = useState([1, 2, lastIndex]);

  return (
    <div className="flex gap-[48px] mt-[48px]">
      <section className="w-[30%]">
        <SearchWords
          data={words}
          setPage={setPage}
          setPageIndex={setPageIndex}
          lastIndex={lastIndex}
          setFilterWords={setFilterWords}
        />
      </section>
      <section className="w-[70%]">
        <WordsByOrder
          data={filterWords}
          page={page}
          setPage={setPage}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          startIndex={startIndex}
          endIndex={endIndex}
          lastIndex={lastIndex}
          gptRedirect={gptRedirect}
        />
      </section>
    </div>
  );
};

export default WordList;
