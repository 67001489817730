import React, { useEffect, useState } from "react";
import EditIcon from "../../Assets/Svgs/EditIcon.svg";
import SaveIcon from "../../Assets/Svgs/SaveIcon.svg";
import DropDown from "./DropDown";
// import Designer from "../../Assets/Svgs/Designer.svg";
import { useUseContext } from "../../Hooks/useUseContext";
import axios from "axios";
import { useNavigate } from "react-router";
import "../Buttons/Button.css";
import Validation from "./validation";
// let initialState = {
//   contact_number: null,
//   location: null,
//   reading_proficiency: "",
//   writing_proficiency: "",
//   speaking_proficiency: "",
// };
const Profile = () => {
  const { userInfo, setUserInfo } = useUseContext();
  // console.log(userInfo, "info");
  // const [user, setUser] = useState(initialState);
  const [response, setResponse] = useState(false);
  const [saved, setSaved] = useState(false);
  let [errors, setErrors] = useState({});
  // console.log(storedUserInfo);
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_API_URL;
  // State to store the selected option
  const [selectedOption, setSelectedOption] = useState("educational");

  const handleInputChange = (event) => {
    setUserInfo({ ...userInfo, Institute: event.target.value });
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiKey}/get_user_details`, {
          withCredentials: true,
        });
        // console.log(response.data, "res");
        // console.log(response.data, "res");
        setUserInfo(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchData();
  }, [response]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiKey}/user_info`, {
          withCredentials: true,
        });
        // console.log(response.data, "res");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchData();
  }, []);
  //functions
  function handleChange({ target }) {
    setUserInfo({ ...userInfo, [target.name]: target.value });
  }
  // console.log(userInfo, "user");
  const handlePostRequest = async () => {
    // console.log(userInfo, "userinfo");
    console.log(userInfo);

    const submitErrors = Validation(userInfo);
    // console.log(submitErrors, "suberr");
    setErrors(Validation(userInfo));

    if (Object.keys(submitErrors).length === 0) {
      try {
        //api for practised words
        const response = await axios.post(
          `${apiKey}/add_user_details`,
          userInfo,
          {
            withCredentials: true,
          }
        );
        // console.log(response.data);
        setResponse(!response); //to trigger rerender
        //for save message

        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 1500);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    }
  };
  // console.log(errors, "err");

  return (
    <div className="flex ">
      <section className="">
        {/* <h3 className="text-[18px] font-bold">Edit Profile</h3> */}
        <img
          src={userInfo.picture}
          alt="img"
          className="w-[120px] rounded-[50%]"
        />
      </section>
      <section className="grow">
        <section className="flex items-center ms-[28px]">
          <p className="text-[14px] text-[#2E2E3080]">Name</p>
          <div className="border-b-[2px] flex items-center justify-between w-full ms-[12px] cursor-not-allowed">
            <div>
              <input
                value={userInfo.user_name}
                type="text"
                className="w-full bg-transparent border-none outline-none  
                focus:ring-0 placeholder-black text-[14px] placeholder:text-[14px] caret-transparent"
              />
            </div>
            <div className="me-[12px]">
              {/* <img src={EditIcon} alt="edit" /> */}
            </div>
          </div>
        </section>
        <section className="flex items-center mt-[18px] ms-[38px]">
          <p className="text-[14px] text-[#2E2E3080]">Email</p>
          <div className="border-b-[2px] flex items-center justify-between w-full ms-[12px] cursor-not-allowed">
            <div>
              <input
                value={userInfo.email}
                type="text"
                className="w-full bg-transparent border-none outline-none caret-transparent
                focus:ring-0 placeholder-black text-[14px] placeholder:text-[14px]"
              />
            </div>
            <div className="me-[12px]">
              {/* <img src={EditIcon} alt="edit" /> */}
            </div>
          </div>
        </section>
        <section className="flex items-center mt-[18px] ms-[28px] ">
          <p className="text-[14px] text-[#2E2E3080]">Phone</p>
          <div className="border-b-[2px] flex items-center justify-between w-full ms-[12px] relative">
            <div className="w-full">
              <input
                name="contact_number"
                value={userInfo.contact_number ? userInfo.contact_number : ""}
                onChange={(e) => handleChange(e)}
                type="text"
                className="w-full bg-transparent border-none outline-none 
                focus:ring-0 placeholder-black text-[14px] placeholder:text-[14px]"
              />
            </div>
            <div className="me-[12px]">
              <img src={EditIcon} alt="edit" />
            </div>
            {errors.contact_number && (
              <p className="absolute text-[#EC1A23] text-[10px] mt-[50px]">
                {errors.contact_number}
              </p>
            )}
          </div>
        </section>
        <section className="flex items-center mt-[18px]">
          <p className="text-[14px] text-[#2E2E3080]">Location</p>
          <div className="border-b-[2px] flex items-center justify-between w-full ms-[12px]  relative">
            <div className="w-full">
              <input
                name="location"
                value={userInfo.location ? userInfo.location : ""}
                onChange={(e) => handleChange(e)}
                type="text"
                className="w-full bg-transparent border-none outline-none 
                focus:ring-0 placeholder-black text-[14px] placeholder:text-[14px]"
              />
            </div>
            <div className="me-[12px]">
              <img src={EditIcon} alt="edit" />
            </div>
            {errors.location && (
              <p className="absolute text-[#EC1A23] text-[10px] mt-[50px]">
                {errors.location}
              </p>
            )}
          </div>
        </section>
        <section className="flex justify-between">
          <p className="text-[14px] text-[#2E2E3080]  mt-[24px] mb-[6px]">
            Select Education institute/ Work organisation
          </p>
          <select
            className="bg-white text-[12px] mt-[10px] "
            onChange={handleInputChange}
          >
            <option value="educational">Educational Institute</option>
            <option value="work">Work Organization</option>
          </select>
          {errors.Institute && (
            <p className="absolute text-[#EC1A23] text-[10px] mt-[50px]">
              {errors.Institute}
            </p>
          )}
        </section>
        <section>
          <div className="flex flex-col">
            <label className="text-[14px] text-[#2E2E3080]  mt-[12px] mb-[6px]">
              {selectedOption === "educational"
                ? "Educational Institute"
                : "Work Organization"}
            </label>
            <input
              name="Institute_name"
              value={userInfo.Institute_name}
              onChange={handleChange}
              type="text"
              className="text-[14px] text-black mt-2 p-1 border-2"
            />
          </div>
          {errors.Institute_name && (
            <p className="absolute text-[#EC1A23] text-[10px] mt-[5px]">
              {errors.Institute_name}
            </p>
          )}
        </section>
        <section className="flex justify-between mt-[18px] text-[14px]">
          <div
            className={`  ${
              saved ? "visible" : "invisible"
            } flex items-center justify-between gap-[6px]`}
          >
            <div>
              <img className="w-3" src={SaveIcon} alt="img" />
            </div>
            <p className="text-[#13CE66]">Successfully Saved</p>
          </div>
          <div
            onClick={handlePostRequest}
            className={`${
              !saved ? "bg-[#13CE66]" : "bg-[#808080]"
            } px-[16px] py-[8px]   text-white rounded-[6px]  cursor-pointer inner-shadow leading-none `}
          >
            Save
          </div>
        </section>
      </section>
    </div>
  );
};

export default Profile;
