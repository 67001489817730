import React, { useState, useEffect, useRef } from "react";
import Acquired from "./Performance/Acquired";
import Practice from "./Performance/Practice";
import Filter from "../Filter/Filter";
import WordList from "./WordList/WordList";
import Chart from "./Chart";
import Graph from "./Graph";
import "./Dashboard.css";
import { useUseContext } from "../../Hooks/useUseContext";
// import popper from "../../Assets/Svgs/party-popper.svg";
import warning from "../../Assets/Svgs/warning.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import info from "../../Assets/Svgs/info.svg";

const UserPerformance = ({ data, select, setSelect, dataByTime }) => {
  // const [MaxCountReached, setMaxCountReached] = useState(0);
  const MaxCountReached = useRef(0);
  const [isDismissed300, setIsDismissed300] = useState(false);
  const [isDismissed100, setIsDismissed100] = useState(false);
  const [gptRedirect, setGptRedirect] = useState("");

  const handleGptStateWord = () => {
    setGptRedirect("word");
  };

  const handleGptStatePhrase = () => {
    setGptRedirect("phrase");
  };

  const navigate = useNavigate();
  const handlePracticeNavigate = () => {
    navigate("/startLearning");
  };
  const apiKey = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiKey}/check_limit_exceeded`, {
          withCredentials: true,
        });
        const data = response.data;

        // setMaxCountReached(data.count);
        MaxCountReached.current = data.count;
        // console.log(MaxCountReached.current);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleDismiss300 = () => {
    setIsDismissed300(true);
  };

  const handleDismiss100 = () => {
    setIsDismissed100(true);
  };

  const { userInfo, setType, setStatus, setLetter, setAlpInd, setDay } =
    useUseContext();

  // console.log(storedUserInfo);
  //reusable variables
  let articleWords = data.acquired?.word?.article || 0;
  let videoWords = data.acquired?.word?.video || 0;
  let manualWords = data.acquired?.word?.manual || 0;
  let articlePhrases = data.acquired?.phrase?.article || 0;
  let videoPhrases = data.acquired?.phrase?.video || 0;
  let manualPhrases = data.acquired?.phrase?.manual || 0;
  // console.log(videoPhrases);
  //for practicedwords and phrases
  let practicedWords = data?.practised?.word?.practised || 0;
  let practicedPhrases = data?.practised?.phrase?.practised || 0;
  //for words and phrase i know
  let skippedWords = data?.practised?.word?.skipped || 0;
  let skippedPhrases = data?.practised?.phrase?.skipped || 0;

  //for totalwords and words acquired
  let totalWordsAcquired =
    (articleWords.E || 0) +
    (articleWords.U || 0) +
    (videoWords.E || 0) +
    (videoWords.U || 0) +
    (manualWords.E || 0) +
    (manualWords.U || 0);
  let totalPhrasesAcquired =
    (articlePhrases.E || 0) +
    (articlePhrases.U || 0) +
    (videoPhrases.E || 0) +
    (videoPhrases.U || 0) +
    (manualPhrases.E || 0) +
    (manualPhrases.U || 0);
  // calculations for words
  let EssentialWords = Math.round(
    (((articleWords.E || 0) + (videoWords.E || 0) + (manualWords.E || 0)) /
      totalWordsAcquired) *
      100
  );
  let UncategorizedWords = Math.round(
    (((articleWords.U || 0) + (videoWords.U || 0) + (manualWords.U || 0)) /
      totalWordsAcquired) *
      100
  );
  const acquiredWordData = [
    // { value: basicWords || 0 },
    { value: EssentialWords || 0 },
    { value: UncategorizedWords || 0 },
  ];
  const isWordDataAcquired = acquiredWordData.reduce(
    (acc, cur) => acc + cur.value,
    0
  );
  //calculations for phrases
  let EssentialPhrases = Math.round(
    (((articlePhrases.E || 0) +
      (videoPhrases.E || 0) +
      (manualPhrases.E || 0)) /
      totalPhrasesAcquired) *
      100
  );
  let UncategorizedPhrases = Math.round(
    (((articlePhrases.U || 0) +
      (videoPhrases.U || 0) +
      (manualPhrases.U || 0)) /
      totalPhrasesAcquired) *
      100
  );
  const acquiredPhraseData = [
    // { value: basicWords || 0 },
    { value: EssentialPhrases || 0 },
    { value: UncategorizedPhrases || 0 },
  ];
  const isPhraseDataAcquired = acquiredPhraseData.reduce(
    (acc, cur) => acc + cur.value,
    0
  );
  // total words by source calculation
  let totalWordFromArticles = (articleWords.E || 0) + (articleWords.U || 0);
  let totalWordFromVideos = (videoWords.E || 0) + (videoWords.U || 0);
  let totalWordFromManual = (manualWords.E || 0) + (manualWords.U || 0);
  let wordsFromArticles = Math.round(
    (totalWordFromArticles / totalWordsAcquired) * 100
  );

  let wordsFromVideos = Math.round(
    (totalWordFromVideos / totalWordsAcquired) * 100
  );

  let wordsFromManual = Math.round(
    (totalWordFromManual / totalWordsAcquired) * 100
  );
  //for source chart
  const sourceWordData = [
    { value: wordsFromVideos || 0 },
    { value: wordsFromArticles || 0 },
    { value: wordsFromManual || 0 },
  ];
  // const sourceData = [{ value: 0 }, { value: 0 }];
  const isSourceWordDataAcquired = sourceWordData.reduce(
    (acc, cur) => acc + cur.value,
    0
  );
  // total phrases by source calculation
  let totalPhraseFromArticles =
    (articlePhrases.E || 0) + (articlePhrases.U || 0);
  let totalPhraseFromVideos = (videoPhrases.E || 0) + (videoPhrases.U || 0);
  let totalPhraseFromManual = (manualPhrases.E || 0) + (manualPhrases.U || 0);
  let PhrasesFromArticles = Math.round(
    (totalPhraseFromArticles / totalPhrasesAcquired) * 100
  );

  let PhrasesFromVideos = Math.round(
    (totalPhraseFromVideos / totalPhrasesAcquired) * 100
  );

  let PhrasesFromManual = Math.round(
    (totalPhraseFromManual / totalPhrasesAcquired) * 100
  );
  //for source chart
  const sourcePhraseData = [
    { value: PhrasesFromVideos || 0 },
    { value: PhrasesFromArticles || 0 },
    { value: PhrasesFromManual || 0 },
  ];
  // const sourceData = [{ value: 0 }, { value: 0 }];
  const isSourcePhraseDataAcquired = sourcePhraseData.reduce(
    (acc, cur) => acc + cur.value,
    0
  );

  //functions
  function handleAcquiredWords() {
    setLetter("A");
    setSelect({
      ...select,
      selected: true,
      words: true,
      phrases: false,
      practisedWords: false,
      skippedWords: false,
      practisedPhrases: false,
      skippedPhrases: false,
    });
  }
  function handleAcquiredPhrases() {
    setLetter("A");
    setSelect({
      ...select,
      selected: true,
      words: false,
      phrases: true,
      practisedWords: false,
      skippedWords: false,
      practisedPhrases: false,
      skippedPhrases: false,
    });
  }
  function handlePracticedWords() {
    setType("word");
    setStatus("practised");
    setLetter("A");
    setAlpInd(0);
    setSelect({
      ...select,
      selected: false,
      practisedWords: true,
      words: false,
      phrases: false,
      skippedWords: false,
      practisedPhrases: false,
    });
  }
  function handlePracticedPhrases() {
    setType("phrase");
    setStatus("practised");
    setLetter("A");
    setAlpInd(0);
    setSelect({
      ...select,
      selected: false,
      practisedWords: false,
      words: false,
      phrases: false,
      skippedWords: false,
      practisedPhrases: true,
      skippedPhrases: false,
    });
  }
  function handleSkippedWords() {
    setType("word");
    setStatus("skipped");
    setLetter("A");
    setAlpInd(0);
    setSelect({
      ...select,
      selected: false,
      skippedWords: true,
      practisedWords: false,
      words: false,
      phrases: false,
      practisedPhrases: false,
      skippedPhrases: false,
    });
  }
  function handleSkippedPhrases() {
    setType("phrase");
    setStatus("skipped");
    setLetter("A");
    setAlpInd(0);
    setSelect({
      ...select,
      selected: false,
      skippedWords: false,
      practisedWords: false,
      words: false,
      phrases: false,
      practisedPhrases: false,
      skippedPhrases: true,
    });
  }

  return (
    <>
      {MaxCountReached.current >= 300 ? (
        !isDismissed300 && (
          <div
            className="inline-flex w-full 5xl:h-[124px] 4xl:h-[122px] 3xl:h-[120px] ss:h-[118px] rounded-3xl mb-4"
            style={{ boxShadow: "4px 4px 24px 0px #26397B1F" }}
          >
            <img
              src={warning}
              alt=""
              className="5xl:w-37 4xl:w-36 3xl:w-35 ss:w-34 rounded-l-3xl"
            />
            <div className="flex gap-3 justify-between bg-white w-full rounded-r-3xl p-6">
              <div className="my-auto " style={{ fontFamily: "Inter" }}>
                <span className="text-[#969FAA] font-medium">
                  Hey, {userInfo.user_name}!
                </span>{" "}
                <span className="font-semibold">
                  You have reached the daily limit of 300 essential words and
                  phrases.
                </span>{" "}
                <span className="text-[#969FAA] font-medium">
                  Practice them or else we pack up.
                </span>
              </div>
              <div className="flex gap-2">
                <div
                  className="5xl:w-[300px] 4xl:w-[250px] 3xl:w-[200px] ss:w-[200px]  text-center bg-[#AA7C48] text-white rounded-2xl font-semibold  flex items-center justify-center cursor-pointer"
                  style={{ fontFamily: "Inter" }}
                  onClick={handlePracticeNavigate}
                >
                  Let's Practice
                </div>

                <div
                  className="5xl:w-[300px] 4xl:w-[250px] 3xl:w-[200px] ss:w-[200px] h-[74px] text-center rounded-2xl font-semibold border border-[#D0D5DD] flex items-center justify-center cursor-pointer"
                  style={{ fontFamily: "Inter" }}
                  onClick={handleDismiss300}
                >
                  Dismiss
                </div>
              </div>
            </div>
          </div>
        )
      ) : MaxCountReached.current >= 100 ? (
        !isDismissed100 && (
          <div
            className="inline-flex w-full 5xl:h-[124px] 4xl:h-[122px] 3xl:h-[120px] ss:h-[118px] rounded-3xl mb-4"
            style={{ boxShadow: "4px 4px 24px 0px #26397B1F" }}
          >
            <img
              src={warning}
              alt=""
              className="5xl:w-37 4xl:w-36 3xl:w-35 ss:w-34 rounded-l-3xl"
            />
            <div className="flex gap-3 justify-between bg-white w-full rounded-r-3xl p-6">
              <div className="my-auto" style={{ fontFamily: "Inter" }}>
                <span className="text-[#969FAA] font-medium">
                  Hey, {userInfo.user_name}!
                </span>{" "}
                <span className="font-semibold">
                  You have acquired 100 words and phrases!
                </span>{" "}
                <span className="text-[#969FAA] font-medium">
                  Practice their contextual usage now.
                </span>
              </div>
              <div className="flex gap-2">
                <div
                  className="5xl:w-[300px] 4xl:w-[250px] 3xl:w-[200px] ss:w-[200px]  text-center bg-[#AA7C48] text-white rounded-2xl font-semibold  flex items-center justify-center cursor-pointer"
                  style={{ fontFamily: "Inter" }}
                  onClick={handlePracticeNavigate}
                >
                  <Link to="/startLearning">Let's Practice</Link>
                </div>
                <div
                  className="5xl:w-[300px] 4xl:w-[250px] 3xl:w-[200px] ss:w-[200px] h-[74px] text-center rounded-2xl font-semibold border border-[#D0D5DD] flex items-center justify-center cursor-pointer"
                  style={{ fontFamily: "Inter" }}
                  onClick={handleDismiss100}
                >
                  Dismiss
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div></div>
      )}
      <main className="">
        <div>
          <section className="flex justify-between items-center">
            <div>
              <div className="font-black text-[34px] font-[Cardo]">
                Hello {userInfo.user_name},
              </div>

              <div className="text-[18px] ">
                Here’s your performance on Glibzter
              </div>
            </div>
            <div>
              <Filter />
            </div>
          </section>
          <section className="flex justify-between gap-[48px] mt-[48px] leading-none">
            {/* acquired={data.acquired} */}
            <div
              onClick={() => {
                setType("word");
                setDay(null);
              }}
            >
              <Acquired
                path={true}
                handleClick={handleAcquiredWords}
                data={totalWordsAcquired}
                name={"Words Acquired"}
                select={select.words}
                setSelect={setSelect}
              />
            </div>
            <div>
              {/* <Acquired data={0} name={"Phrases Acquired"} /> */}
              <Acquired
                onClick={() => {
                  setType("phrase");
                  setDay(null);
                }}
                handleClick={handleAcquiredPhrases}
                data={totalPhrasesAcquired}
                name={"Phrases Acquired"}
                select={select.phrases}
                setSelect={setSelect}
              />
            </div>

            <div className="flex flex-col justify-between">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setType("word");
                  setDay(null);
                  handleGptStateWord();
                }}
              >
                {" "}
                {/* onClick={handlePracticedWords} */}
                <Practice
                  data={practicedWords}
                  name={"Words Practiced"}
                  select={select.practisedWords}
                  handleClick={handlePracticedWords}
                />
              </div>
              <div
                onClick={() => {
                  setType("phrase");
                  setDay(null);
                  handleGptStatePhrase();
                }}
                className="cursor-pointer"
              >
                <Practice
                  data={practicedPhrases}
                  name={"Phrases Practiced"}
                  select={select.practisedPhrases}
                  handleClick={handlePracticedPhrases}
                />
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setType("word");
                  setDay(null);
                  handleGptStateWord();
                }}
              >
                <Practice
                  data={skippedWords}
                  name={"Words I Know"}
                  select={select.skippedWords}
                  handleClick={handleSkippedWords}
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setType("phrase");
                  setDay(null);
                  handleGptStatePhrase();
                }}
              >
                <Practice
                  data={skippedPhrases}
                  name={"Phrases I Know"}
                  select={select.skippedPhrases}
                  handleClick={handleSkippedPhrases}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
      <main>
        {!select.selected ? (
          <WordList gptRedirect={gptRedirect} />
        ) : (
          <>
            <Chart
              isWord={select.words}
              data={data}
              acquiredData={
                select.words ? acquiredWordData : acquiredPhraseData
              }
              isDataAcquired={
                select.words ? isWordDataAcquired : isPhraseDataAcquired
              }
              sourceData={select.words ? sourceWordData : sourcePhraseData}
              isSourceDataAcquired={
                select.words
                  ? isSourceWordDataAcquired
                  : isSourcePhraseDataAcquired
              }
            />
            <Graph data={dataByTime} />
          </>
        )}
      </main>
      <div className="mt-[48px] inline-flex gap-2">
        <img src={info} alt="" className="w-[20px]" />
        <div style={{ fontFamily: "lato" }}>
          <span className="text-[#808080] font-bold 5xl:text-lg 4xl:text-lg 3xl:text-base ss:text-base">
            There is a{" "}
          </span>
          <span className="font-bold text-[#2E2E30] 5xl:text-lg 4xl:text-lg 3xl:text-base ss:text-base">
            daily limit of 300 'Essential' words and phrases.{" "}
          </span>
          <span className="text-[#808080] font-bold 5xl:text-lg 4xl:text-lg 3xl:text-base ss:text-base">
            Glibzter Immersive will stop curating words and phrases till you
            start practicing them.
          </span>
        </div>
      </div>
    </>
  );
};

export default UserPerformance;
