import ReDirect from "../../../Assets/Svgs/redirectGptIcon.svg";
import { Link } from "react-router-dom";
import PaginationBack from "../../../Assets/Svgs/paginationBackIcon.svg";
import PaginationNext from "../../../Assets/Svgs/paginationNextIcon.svg";
// import PaginationFadeBack from "../../../Assets/Svgs/paginationFadeBackIcon.svg";
// import PaginationFadeNext from "../../../Assets/Svgs/paginationFadeNextIcon.svg";

const WordsByOrder = ({
  data,
  page,
  setPage,
  pageIndex,
  setPageIndex,
  startIndex,
  endIndex,
  lastIndex,
  gptRedirect,
}) => {
  //functions
  // function pagination(i) {
  //   setPage((pre) => pre + i);
  // }
  // console.log(page);
  function pageBack() {
    if (!(page <= 1)) setPage((pre) => pre - 1);
    if (page < pageIndex[1] && page > 1) {
      setPageIndex([pageIndex[0] - 1, pageIndex[1] - 1, lastIndex]);
    }
  }
  function pageNext() {
    if (page < lastIndex) setPage((pre) => pre + 1);
    if (page >= 2 && page <= lastIndex - 2) {
      setPageIndex([pageIndex[0] + 1, pageIndex[1] + 1, lastIndex]);
    }
  }
  let wordsArray = data.slice(startIndex, endIndex).map((el, ind) => {
    return (
      <Link
        to={`/glibzterAI?word=${el}&to=dashboard&gptredirect=${gptRedirect}`}
      >
        <div
          className="cols-span-1 leading-none py-[12px] flex items-center group  cursor-pointer gap-[10px] "
          key={ind}
        >
          <div className="text-[18px] group-hover:text-[#AA7C48]">{el}</div>
          <div className="invisible group-hover:visible">
            <img src={ReDirect} alt="redirect" />
          </div>
        </div>
      </Link>
    );
  });
  let displayWords = wordsArray.length ? wordsArray : "";

  return (
    <div>
      <div>
        {displayWords && (
          <div className="bg-white p-[24px] rounded-[12px] grid grid-cols-4 min-h-[300px]  ">
            {displayWords}
          </div>
        )}
      </div>

      <section>
        {wordsArray.length ? (
          <div className="flex justify-center mt-[24px] ">
            <section className="flex items-center gap-[8px] text-[10px]">
              {/* {lastIndex >= 3 ? ( */}
              <div
                onClick={pageBack}
                className={`border py-[8px] px-[8px] rounded-[3px] cursor-pointer hover:bg-[#AA7C48] hover:border-transparent`}
              >
                <img className="w-[8px] " src={PaginationBack} alt="back" />
              </div>

              <div
                // onClick={() => pagination(page)}
                // cursor-pointer hover:bg-[#AA7C48] hover:border-transparent hover:text-whit
                className={`${
                  pageIndex[0] === page &&
                  "bg-[#AA7C48] border-transparent text-white"
                } border py-[4px] px-[8px] rounded-[3px]  
               
               `}
              >
                {pageIndex[0]}
              </div>
              {lastIndex >= 2 && (
                <div
                  // onClick={() => pagination(page)}
                  className={`${
                    pageIndex[1] === page &&
                    "bg-[#AA7C48] border-transparent text-white "
                  } border py-[4px] px-[8px] rounded-[3px]   e`}
                >
                  {pageIndex[1]}
                </div>
              )}
              {lastIndex >= 3 && <div>...</div>}
              {lastIndex >= 3 && (
                <div
                  className={`${
                    lastIndex === page &&
                    "bg-[#AA7C48] border-transparent text-white"
                  } border py-[4px] px-[8px] rounded-[3px] `}
                >
                  {lastIndex}
                </div>
              )}

              <div
                onClick={pageNext}
                className={`border py-[4px] px-[8px] rounded-[3px] cursor-pointer hover:bg-[#AA7C48] hover:border-transparent`}
              >
                <img className="w-[9px] " src={PaginationNext} alt="Next" />
              </div>
            </section>
          </div>
        ) : (
          <p className=" w-full  min-h-[200px] bg-white p-[24px] rounded-[12px] flex items-center justify-center ">
            No Results Found
          </p>
        )}
      </section>
    </div>
  );
};

export default WordsByOrder;

//  <a
//         href={`https://www.shabdkosh.com/dictionary/english-hindi/${el}/${el}-meaning-in-hindi`}
//         target="_blank"
//         rel="noreferrer"></a>
