import React from "react";
import "../../Dashboard/Dashboard.css";
import PracticeButton from "../../Buttons/PracticeButton";
import { Link } from "react-router-dom";
import { numberConversion } from "../../../Helpers/numberConversion.js";

const Acquired = ({ name, data, select, setSelect, handleClick, path }) => {
  const color = {
    backgroundColor: select ? "#FFF5EB" : "white",
  };

  return (
    <div
      onClick={handleClick}
      style={color}
      className={`5xl:px-[48px] 4xl:px-[18px] 3xl:px-[18px] ss:px-[18px] py-[24px] border-[2px] rounded-[12px] cursor-pointer
       bg-white text-center leading-none group hover:bg-[#FFF5EB] shadow-lg`}
    >
      <div className="5xl:text-[22px] 4xl:text-[22px] 3xl:text-[20px] ss:text-[18px] font-medium">
        {name}
      </div>

      <div className="text-[#AA7C48] 5xl:text-[64px] 4xl:text-[64px] 3xl:text-[60px] ss:text-[56px] pt-[24px] pb-[48px] leading-none font-bold font-[Cardo]">
        {numberConversion(data)}
      </div>
      {path ? (
        <Link to="/startLearning">
          <PracticeButton select={select} setSelect={setSelect} />
        </Link>
      ) : (
        <Link to="/startLearnPhrases">
          <PracticeButton select={select} setSelect={setSelect} />
        </Link>
      )}
    </div>
  );
};

export default Acquired;

// const AcquiredPhrase = ({ name, data }) => {
//   return (
//     <div
//       className={`px-[48px] py-[24px] border-[2px] rounded-[12px] text-center leading-none group bg-white hover:bg-[#FFF5EB] shadow-lg`}>
//       <div className="text-[22px] font-medium">{name}</div>

//       <div className="text-[#AA7C48] text-[64px] pt-[24px] pb-[48px] leading-none font-[Cardo] font-bold">
//         {numberConversion(data)}
//       </div>

//       <PracticeButton />
//     </div>
//   );
// };

// export { AcquiredPhrase };
