import React, { useState } from "react";
import { timeConversion } from "../../../Helpers/timeConversion";

const Bar = ({
  singleData,
  highestData,
  data,
  index,
  interval,
  individualData,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  let time = timeConversion(data[index], interval); //filter time based on index

  let article = individualData[index].article;
  let video = individualData[index].video;
  let manual = individualData[index].manual;
  let admin =individualData[index].admin;
  // console.log(data[index], interval, "time");
  // let bySource = individualData.map((el, ind) => {
  //   return (
  //     <div

  //       className="absolute p-[12px] rounded-[12px] bg-[#F6EEE5]  shadow-md  top-0 text-[8px] left-[70%] w-[70px]">
  //       {" "}
  //       <p>{`Article : ${el.article}`}</p> <p>{`Video : ${el.video}`}</p>{" "}
  //       <p>{`Manual : ${el.manual}`}</p>{" "}
  //     </div>
  //   );
  // });
  let percentage = Math.round((singleData / highestData) * 100); //calculation for percentage
  const fillHeight = { height: `${percentage}%` };
  return (
    <div
      className="relative"
      key={index}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div
        className="h-full w-[20px] bg-[#F6EEE5] rounded-[50px] 
      flex flex-col justify-end m-auto overflow-hidden cursor-pointer ">
        {" "}
        <div
          style={fillHeight}
          className={` bg-[#AA7C48] rounded-[50px] transition-all duration-300 ease-out`}></div>
      </div>
      <div className="text-[14px] pt-[12px] ">{time}</div>
      {isHovered && (
        <div
          className="absolute z-10 p-[6px] rounded-[12px] bg-[#F6EEE5] text-[#AA7C48] font-medium  shadow-md flex flex-col gap-[2px] items-center justify-center
          top-0 text-[12px] left-[70%] w-[85px]">
          {" "}
          <p>{`Article : ${article}`}</p> <p>{`Video : ${video}`}</p>{" "}
          <p>{`Manual : ${manual}`}</p>    <p>{`Admin : ${admin}`}</p>
        </div>
      )}
    </div>
  );
};

export default Bar;
