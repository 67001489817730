import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Source from "../Components/Dashboard/Source";
import Completed from "../Components/Home/Completed";
import Ongoing from "../Components/Home/Ongoing";
import Upcoming from "../Components/Home/Upcoming";
import Video from "../Components/Home/Video";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const apiKey = process.env.REACT_APP_API_URL;
  const [videos, setVideos] = useState([]);
  const [words, setWords] = useState([]);
  const [phrases, setPhrases] = useState([]);
  console.log(phrases, "phrases");
  const [trackUser, setTrackUser] = useState(1);
  const [trackUserPhrase, setTrackUserPhrase] = useState(1);
  const navigate = useNavigate();
  const handleRemoveCookies = () => {
    document.cookie = "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  //<!---For scroll--->
  const divRef = useRef(null);
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [words]);
  const divRefPhrase = useRef(null);
  useEffect(() => {
    if (divRefPhrase.current) {
      divRefPhrase.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [phrases]);
  //<!---For scroll--->
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiKey}/user_info`, {
          withCredentials: true,
        });
        setTrackUser(response.data.current_day_word);
        setTrackUserPhrase(response.data.current_day_phrase);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    fetch(`${apiKey}/videos/`)
      .then((response) => response.json())
      .then((data) => setVideos(data.videos))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      });
  }, []);

  //days of word
  useEffect(() => {
    fetch(`${apiKey}/words/`)
      .then((response) => response.json())
      .then((data) => {
        const wordsData = data.words;
        const Data = [];

        Object.keys(wordsData).forEach((key) => {
          const day = wordsData[key];
          const curr_day = key;
          const word = day.practice_words;
          const theme = day.theme;
          let Words = { day: curr_day, wordsToPractise: word, theme: theme };
          Data.push(Words);
        });

        setWords(Data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      });
  }, []);

  //days of phrase

  useEffect(() => {
    fetch(`${apiKey}/phrases/`)
      .then((response) => response.json())
      .then((data) => {
        const phrasesData = data.phrases;
        const Data = [];

        Object.keys(phrasesData).forEach((key) => {
          const day = phrasesData[key];
          const curr_day = key;
          const phrase = day.practice_words;
          const theme = day.theme;
          let phrases = {
            day: curr_day,
            wordsToPractise: phrase,
            theme: theme,
          };
          Data.push(phrases);
        });

        setPhrases(Data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          handleRemoveCookies();
          navigate("/");
        }
      });
  }, []);

  // for scroll

  return (
    <div className="flex">
      <div className="sticky top-0 h-screen">
        <Sidebar />
      </div>
      <div className="grow px-[38px] pb-[48px] bg-[#fbf9f8] overflow-y-auto ">
        <section className="flex justify-end mt-[20px] ">
          <Source />
        </section>
        <main>
          <div className="flex flex-col gap-8 mt-12">
            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <div className="flex flex-col min-w-[40%]">
                  <div
                    className="text-[24px] font-bold leading-10"
                    style={{ fontFamily: "Cardo" }}
                  >
                    Daily Dozen
                  </div>
                  <div>12 words to boost your word power today</div>
                </div>
                <div className=" flex items-center">
                  <div className="h-0.5 w-full bg-[#aa7c48]"></div>
                </div>
                {/* trackUserPhrase */}
              </div>
              <div className="flex gap-4 overflow-x-auto scroll-smooth max-w-none">
                {words &&
                  words.map((el, ind) => {
                    if (ind + 1 <= trackUser) {
                      return (
                        <Completed
                          theme={el.theme}
                          day={el.day}
                          linkTo={true}
                          key={ind}
                        />
                      );
                    }
                    if (ind + 1 === trackUser + 1) {
                      return (
                        <div ref={divRef}>
                          <Ongoing
                            theme={el.theme}
                            day={el.day}
                            linkTo={true}
                            key={ind}
                          />
                        </div>
                      );
                    }
                    if (ind + 1 > trackUser) {
                      return <Upcoming day={el.day} key={ind} />;
                    }
                  })}
              </div>
            </div>
            <div className="flex flex-col gap-4 overflow-x-auto">
              <div className="flex gap-4">
                <div className="flex flex-col min-w-[40%]">
                  <div
                    className="text-[24px] font-bold leading-10"
                    style={{ fontFamily: "Cardo" }}
                  >
                    Take 20
                  </div>
                  <div>20 phrases to make you sound more interesting today</div>
                </div>
                <div className="flex items-center">
                  <div className="h-0.5 w-full bg-[#aa7c48]"></div>
                </div>
              </div>
              <div className="flex gap-4 overflow-x-auto scroll-smooth max-w-none">
                {phrases &&
                  phrases.map((el, ind) => {
                    if (ind + 1 <= trackUserPhrase) {
                      return (
                        <Completed theme={el.theme} day={el.day} key={ind} />
                      );
                    }
                    if (ind + 1 === trackUserPhrase + 1) {
                      return (
                        <div ref={divRefPhrase}>
                          {" "}
                          <Ongoing theme={el.theme} day={el.day} key={ind} />
                        </div>
                      );
                    }
                    if (ind + 1 > trackUserPhrase) {
                      return <Upcoming day={el.day} key={ind} />;
                    }
                  })}
              </div>
            </div>
            {videos.map((video, index) => (
              <div className="flex flex-col gap-4">
                <div className="flex gap-4 ">
                  <div
                    className="text-2xl font-bold leading-10 width-[30%]"
                    style={{ fontFamily: "Cardo" }}
                  >
                    {video.title}
                  </div>
                  <div className=" flex items-center">
                    <div className="h-0.5 w-full bg-[#aa7c48]"></div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <Video videoLink={video.youtube_link_1} />
                  <Video videoLink={video.youtube_link_2} />
                  <Video videoLink={video.youtube_link_3} />
                  <Video videoLink={video.youtube_link_4} />
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Home;
