import { useEffect, useState } from "react";

export function useColorIndication(progressPercentage) {
  const [colorIndicator, setColorIndicator] = useState("red");
  useEffect(() => {
    if (progressPercentage > 0) {
      setColorIndicator("#AA7C48");
    }
    // else if (progressPercentage > 50) {
    //   setColorIndicator("#926D43");
    // } else if (progressPercentage < 50) {
    //   setColorIndicator("#913831 ");
    // }
  }, [progressPercentage]);
  return colorIndicator;
}
