import React from "react";
import Line from "../../Assets/Svgs/Line.svg";
// import templategreen from "../../Assets/Svgs/green.svg";
import templategrey from "../../Assets/Svgs/grey.svg";
// import templatebrown from "../../Assets/Svgs/brown.svg";
// import add from "../../Assets/Svgs/add.svg";
import lock from "../../Assets/Svgs/lock.svg";
// import success from "../../Assets/Svgs/success.svg";
// import swipe from "../../Assets/Svgs/swipe.svg";
// import "./Upcoming.css";

const Upcoming = ({ day }) => {
  return (
    <div>
      <div className="bg-[#AEAEAE] p-[20px] rounded-t-[18px] text-center text-white 5xl:text-sm 4xl:text-sm 3xl:text-sm ss:text-xs "></div>
      <div className="main-2 cursor-not-allowed flex flex-col p-4 gap-4 5xl:w-72 4xl:w-60 3xl:w-60 ss:w-42 items-center rounded-b-xl bg-[#f1f3fb]">
        <div className="first-2 text-[#2e2e30] 5xl:text-sm 4xl:text-sm 3xl:text-sm ss:text-sm font-normal leading-4">
          Add to practice
        </div>
        <div className="second-2">
          <img src={Line} />
        </div>
        <div
          className="third-2 w-40 h-28 flex justify-center items-center bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${templategrey})`,
            backgroundSize: "85%",
          }}
        >
          <div className="third-text-2 flex flex-col bg-white pt-0 px-2 rounded-md">
            <div
              className="text-first-2 text-[#2e2e30] text-base leading-5 pt-1 font-bold "
              style={{ fontFamily: "cardo" }}
            >
              Day
            </div>
            <div
              className="text-second-2 flex justify-center items-center text-[#2e2e30] text-3xl font-bold leading-9 "
              style={{ fontFamily: "cardo" }}
            >
              <div>{day}</div>
            </div>
          </div>
        </div>
        <div className="fourth-2">
          <img src={Line} />
        </div>
        <div className="fifth-2 flex flex-col items-center gap-4 bg-white p-2 w-full justify-center ">
          <div>
            <img src={lock} />
          </div>
          <div className="5xl:text-lg 4xl:text-lg 3xl:text-base ss:text-base">
            Unlocked
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Upcoming;
