import React, { useState } from "react";
import * as d3 from "d3";
// import Slider from "@material-ui/core/Slider";
// import { Box } from "@material-ui/core";
import { useColorIndication } from "../../../Hooks/useColorIndication";

function ProgressArc(props) {
  const { svgWidth, arcWidth, progressPercentage, colorIndicator } = props;
  const svgHeight = svgWidth;
  const arcOuterRadius = svgWidth / 2;
  const arcInnerRadius = svgWidth / 2 - arcWidth;
  const arcGenerator = d3
    .arc()
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius)
    .startAngle(0)
    .cornerRadius(5);
  const progressArc = (value) =>
    arcGenerator({
      endAngle: 2 * Math.PI * value,
    });

  return (
    <div>
      <svg height={svgHeight} width={svgWidth}>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path d={progressArc(1)} opacity="0.2" fill="gray" />
        </g>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path
            d={progressArc(progressPercentage / 100)}
            fill={colorIndicator}
          />
          <text
            style={{ fill: "#AA7C48" }}
            x="0"
            y="0"
            textAnchor="middle"
            dominantBaseline="middle"
            className="font-[Cardo] text-[48px] font-bold ">
            {`${progressPercentage}%`}
          </text>
        </g>
      </svg>
    </div>
  );
}

export function ProgressCircle({ vocabularyStrength }) {
  const svgWidth = 212;
  const arcWidth = 8;
  const [progressPercentage, setProgressPercentage] = useState(
    Math.round(vocabularyStrength)
  );
  const colorIndicator = useColorIndication(progressPercentage);
  //   function valuetext(value) {
  //     return `${value}°C`;
  //   }
  //   function setProgressValue(event, value) {
  //     setProgressPercentage(value);
  //   }

  return (
    <div>
      <ProgressArc
        svgWidth={svgWidth}
        arcWidth={arcWidth}
        progressPercentage={progressPercentage}
        colorIndicator={colorIndicator}
      />
      {/* <Box width="50%">
        <Slider
          defaultValue={50}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider-small-steps"
          step={10}
          marks
          min={0}
          max={100}
          valueLabelDisplay="auto"
          onChange={(event, value) => {
            setProgressValue(event, value);
          }}
        />   </Box> */}
    </div>
  );
}
